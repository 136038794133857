import mitt from 'mitt'
const emitter = mitt()
export const useGlobalEvent = emitter.emit
export const useGlobalEventLister = emitter.on
export function useCheckExistingEmailEvent() {
    return `check_existing_email_event`;
}

export function useLoginEvent() {
    return `login_event`;
}
export function useAddUpdateGuardian() {
    return `add_add-update_guardian`;
}
export function useLoggedIdUserDataPreparationEvent() {
    return `logged_in_user_data_preparation`;
}

export function useSignupDataSubmitEvent() {
    return `signup_data_submit_event`;
}

export function useNotificationEvent() {
    return `notification_event`;
}

export function useNewFeedPostToastEvent() {
    return `new_feed_post_test_event`;
}

export function usePostUploadingToast() {
    return `use_post_uploading_toast`;
}

export function useSingleAddStudentFormEvent(id:any, localId:any) {
    return `single_add_student_form_id_${id}_localId_${localId}`;
}

export function useSingleAddAuthorizedPickupFormEvent(id:any, localId:any) {
    return `single_add_authorized_pickup_form_id_${id}_localId_${localId}`;
}

export function useAddRelationEvent() {
    return `add_relation_event`;
}

export function useAddCategoriesEvent() {
    return `add_categories_event`;
}

export function useSendCategoryEvent() {
    return `add_send_category_event`;
}

export function useAddFeesEvent() {
    return `add_fees_event`;
}
export function useAddCoHost() {
    return `add_coHost_event`;
}
export function useFamiliesPageSendTextEventPopup() {
    return `families_page_send_text_event_popup`;
}
export function useDashboardUserActivityTrackerEventPopup() {
    return `dashboard_user_activity_tracker_event_popup`;
}
export function addUpdateUserDialogue() {
    return `add_update-user-dialogue`;
}
export function userPageRefreshEvent() {
    return `user_page-refresh-event`;
}
export function useFeedLogIn() {
    return `feed-login_event`;
}

export function useInviteGuestToEventPopup() {
    return `invite_guest_to_event_popup`;
}

export function useRefreshCurrentEventGuests() {
    return `refresh_current_event_guests`;
}

export function addCoHostResponse() {
    return `add_co_host_response`;
}

export function transactionTrendDataReceived() {
    return `transactionTrendDataReceived`;
}

export function multiDropDownOptionRemovedFromParent() {
    return `multiDropDownOptionRemovedFromParent`;
}

export function addUpdateAnnouncementDialogue() {
    return `add_update-announcement-dialogue`;
}

export function announcementPageRefreshEvent() {
    return `announcement-page-refresh-event`;
}

export function announcementBannerOpenEvent() {
    return `announcement-banner-open-event`;
}

export function announcementBannerCloseEvent() {
    return `announcement-banner-close-event`;
}

export function notificationDetailDialogue() {
    return `notification-detail-dialogue`;
}

export function qrScannerDialogue() {
    return `qr-scanner-dialogue`;
}
export function receiveCashEvent() {
    return `receive_cash_dialogue`;
}

export function receiveCashEventSuccess() {
    return `receiveCashEventSuccess`;
}

export function usePurchaseTextMessagingPlanDialogue() {
    return `purchaseTextMessagingPlanDialogue`;
}

export function usePaymentProcessingEvent() {
    return `usePaymentProcessingEvent`;
}

export function removeClubsFromMultiProgramDropDown() {
    return `remove_clubs_from_Multi_program_dropdown`;
}

export function removeGradeFromMultiGradeDropDown() {
    return `remove_grade_from_multi_grade_dropdown`;
}

export function addUpdateFeeDialogue() {
    return `add_update-fee-dialogue`;
}

export function feePageRefreshEvent() {
    return `fee_page-refresh-event`;
}

export function useAddClubFeesEvent() {
    return `add_club_fees_event`;
}