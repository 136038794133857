// store
import {$fetch} from "ofetch";
import {userStore} from "~/store/userStore";
import {companyStore} from "~/store/companyStore";

let debug = false
export class NetworkService {
    public post$fetch(url: string, body: any, params: any, key: string, withSecretKey?:boolean, withoutStInBody?:boolean) {
        if(debug) console.log(body)
        const config = useRuntimeConfig()
        const useUserStore = userStore()
        const useCompanyStore = companyStore()
        const token = useUserStore.loggedInUser?.SessionToken
        const requestBody = {...body, 'st': token};
        if(withoutStInBody){
            delete requestBody.st;
        }
        return $fetch(url, {
            body: requestBody,
            params: params,

            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer "+token,
                "secrete-key": withSecretKey?config.public.MESSAGING_SECRET_KEY:'',
                'Content-Type': 'application/json; charset=UTF-8',
                'Accept': 'application/json',
            },
            method: 'post',
        });
    }
}
