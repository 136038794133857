import {defineStore} from 'pinia'
import {NetworkService} from "~/utils/NetworkService";
import type {Company} from "~/models/user";
import type {CompanyDetails} from "~/models/companyDetails"
import type {CompanyModule} from "~/utils/enums/CompanyModule";
import type {CompanySubscription} from "~/models/companySubscription";

// @ts-ignore
export const companyStore = defineStore({
    id: 'company-store',
    persist: true,
    state: () => {
        return {
            companyCode: '',
            eventRegistrationCompanyCode: '',
            companyDetails: <any>{},
            eventRegistrationCompany: <any>{}, //Company details for event registration process
            rsvpGuestCompany: <any>{}, //Company details for rsvp guest accept/reject page
            feedCompany: <any>{}, //Company details for photo contest feed page
            companyImage: <any>{
                oyster: '~/assets/images/company-images/oyster.png',
                stoddert: '~/assets/images/company-images/oyster.png',
                aphelia: '~/assets/images/company-images/oyster.png'
            }
        }
    },
    actions: {
        clearCompanyStore: function () {
          this.clearEventRegistrationData();
          this.clearRsvpGuestData();
          const tmpCompanyDetails = {...this.companyDetails};
          this.companyDetails = <any>{}

          this.companyDetails.banner = tmpCompanyDetails.banner;
          this.companyDetails.logo = tmpCompanyDetails.logo;
          this.companyDetails.code = tmpCompanyDetails.code;
          this.companyDetails.name = tmpCompanyDetails.name;

          this.feedCompany = <any>{};

        },
        clearEventRegistrationData: function () {
          this.eventRegistrationCompany = <any>{}
        },
        clearRsvpGuestData: function () {
            this.rsvpGuestCompany = <any>{}
        },
        async getCompanyDetails(companyId:number) {
            try{
                const config = useRuntimeConfig()
                let url = '/api/company/details';
                const ns = new NetworkService();
                let response = await ns.post$fetch(url, {"company_id": companyId}, null, "GetCompanyDetails" + new Date().getMilliseconds() + "_")
                if(response?.id>0){
                    this.setCompanyDetails(response);
                    return response;
                }else{
                    this.setCompanyDetails({name: 'Aphelia', id: -1, code: 'aphelia'})
                    return false;
                }
            }catch (e){
                console.log(e);
            }
        },

        async sellerOnBoard() {
            try{

                let url = '/api/company/seller-onboard';
                const ns = new NetworkService();
                const config = useRuntimeConfig()

                let response = await ns.post$fetch(url, {"companyId": this.companyDetails.id,'device':'web','from':config.public.NUXT_PUBLIC_SITE_URL+'/settings?seller='+`${this.companyDetails.id}&device=web`}, null, "sellerOnBoard" + new Date().getMilliseconds() + "_")

                let links = response['Result']['links']??[];

                for (let link of links) {
                    if(link['rel']=='action_url'){

                     return link['href'];

                    }
                }
            }catch (e){
                console.log(e);
            }
        },
        async sellerOnBoardStatus() {
            try{

                let url = '/api/company/seller-onboard-status';
                const ns = new NetworkService();
                let response = await ns.post$fetch(url, {"companyId": this.companyDetails.id}, null, "sellerOnBoardStatus" + new Date().getMilliseconds() + "_")

                let result = response['Result']

                return result;
            }catch (e){
                console.log(e);
            }
        },

        async loadCompanyDetailsByCode(companyCode:string, withSetToLocal:boolean) {
            try{
                if(companyCode){
                    const config = useRuntimeConfig()
                    let url = '/api/company/details-by-code';
                    const ns = new NetworkService();
                    let {data} = await ns.post$fetch(url, {"code": companyCode}, null, "getCompanyDetailsByCode" + new Date().getMilliseconds() + "_")
                    if(data?.id){
                        if(withSetToLocal){
                            this.setCompanyDetails(data);
                        }
                        return data;
                    }else{
                        if(withSetToLocal){
                            this.setCompanyDetails({name: 'Aphelia', id: -1, code: 'aphelia'})
                        }
                        return false;
                    }
                }else{
                    if(withSetToLocal){
                        this.setCompanyDetails({name: 'Aphelia', id: -1, code: 'aphelia'})
                    }
                }
            }catch (e){
                console.log(e);
                if(withSetToLocal){
                    this.setCompanyDetails({name: 'Aphelia', id: -1, code: 'aphelia'})
                }
            }
        },

        loadCompanyDetailsAndSetCompanyForEventRegistrationFromServer: async function (companyCode:string) {
            const companyDetails = await this.loadCompanyDetailsByCode(companyCode, false);
            if(companyDetails){
                this.setEventRegistrationCompanyDetails(companyDetails);
                return companyDetails;
            }
        },

        loadCompanyDetailsAndSetCompanyForRsvpGuestFromServer: async function (companyCode:string) {
            const companyDetails = await this.loadCompanyDetailsByCode(companyCode, false);
            if(companyDetails){
                this.setRsvpGuestCompanyDetails(companyDetails);
                return companyDetails;
            }
        },

        loadCompanyDetailsAndSetCompanyForFeedFromServer: async function (companyCode:string) {
            const companyDetails = await this.loadCompanyDetailsByCode(companyCode, false);
            if(companyDetails){
                this.setFeedCompanyDetails(companyDetails);
                return companyDetails;
            }
        },

        setCompanyCode(companyCode:string){
            this.companyCode = companyCode;
        },

        setCompanyDetails(company:any){
            this.companyDetails = company;
            this.companyCode = company.code;
        },

        setEventRegistrationCompanyDetails(company:any){
            this.eventRegistrationCompany = company;
            this.eventRegistrationCompanyCode = company.code;
        },

        setRsvpGuestCompanyDetails(company:any){
            this.rsvpGuestCompany = company;
        },
        setFeedCompanyDetails(company:any){
            this.feedCompany = company;
        },
        hasModule: function (company:CompanyDetails, module:CompanyModule) {
            const found = company.subscriptions?.find((subscription:CompanySubscription)=>subscription.company_id==company.id && subscription.feature.id==module);
            return !!found;
        }
    },
    getters: {
        getCompanyDetailsLocal: state => state.companyDetails,
        getCompanyName: state => state.companyDetails?.name??'',
        getCompanyId: state => state.companyDetails?.id,
        getCompanyLogo: state => state.companyDetails?.logo,
        getCompanyImage: state => state.companyDetails?.banner,
        getCompanyCode: state => state.companyCode,
        getCompanyImageByCompanyCode: state => function (companyCode: string) {
            return state.companyImage[companyCode];
        },
        getEventRegistrationCompany: state => state.eventRegistrationCompany,
        getRsvpGuestCompany: state => state.rsvpGuestCompany as Company,
        getFeedCompany: state => state.feedCompany as Company,
        getHasCheckInModule: state => {
            const subscriptions = state.companyDetails?.subscriptions??[];
            const foundModule = subscriptions.find((item:any)=>item?.feature?.Name=="Checkin");
            return foundModule?.id>0;
        }, getHasPaymentModule: state => {
            const subscriptions = state.companyDetails?.subscriptions??[];
            const foundModule = subscriptions.find((item:any)=>item?.feature?.Name=="Payment");
            return foundModule?.id>0;
        },
        getHasEventModule: state => {
            const subscriptions = state.companyDetails?.subscriptions??[];
            const foundModule = subscriptions.find((item:any)=>item?.feature?.Name=="Event");
            return foundModule?.id>0;
        },
        hasModuleOnCurrentCompany: state => function (moduleId:CompanyModule) {
            const subscriptions = state.companyDetails?.subscriptions??[];
            const foundModule = subscriptions.find((item:any)=>item?.feature?.id==moduleId);
            return foundModule?.id>0;
        }
    }

})
